//import { createSelector } from 'reselect';

export const getApp = state => state.App;

export const getAuthenticated = state => getApp(state).isAuthenticated;

export const getSessionExpired = state => getApp(state).isSessionExpired;

//export const getUserInfo = createSelector(getApp, app => app.userInfo);
export const getUserInfo = state => getApp(state).userInfo;

export const getSettingsOpen = state => getApp(state).isSettingsOpen;

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { getSessionExpired } from 'store/selectors/App';
import { goLogin } from 'store/actions/App';

class ExpiredSessionModal extends React.Component {
  handleClickOk = () => {
    this.props.goLogin(this.props.location);
  };

  render() {
    return (
      <Dialog
        open={this.props.isSessionExpired}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Your session has expired</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You must be logged in to continue working
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClickOk}
            variant="outlined"
            color="primary"
            //autoFocus
          >
            Go to login
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isSessionExpired: getSessionExpired,
});
export default compose(
  withRouter,
  connect(mapStateToProps, { goLogin })
)(ExpiredSessionModal);

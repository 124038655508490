import history from 'components/Routing/history';
import * as api from 'api';
import ActionTypes from '../actions';

export const login_TEST = (login, password) => {
  console.log('>>>>>>>>>> 000', login, password);
  return dispatch => {
    return api.login(login, password).then(res => {
      console.log('>>>>>>>>>> 111', res);
      if (res.status === 'success') {
        dispatch({
          type: ActionTypes.LOGIN,
          payload: res.result.userInfo,
        });
      }

      return res;
    });
  };
};

export const login = (login, password) => {
  return dispatch => {
    return api
      .login(login, password)
      .then(res => {
        if (!res.confirm_required) {
          dispatch({
            type: ActionTypes.LOGIN,
            payload: res,
          });
        }
        return { status: 'success', confirmRequired: res.confirm_required };
      })
      .catch(err => {
        return { status: 'error', errorMsg: err };
      });
  };
};

export const logout = () => {
  return dispatch => {
    return api.logout().then(() => {
      dispatch({
        type: ActionTypes.LOGOUT,
      });
      history.push('/login');
    });
  };
};

export const confirmLogin = code => {
  return dispatch => {
    return api
      .confirmLogin(code)
      .then(res => {
        dispatch({
          type: ActionTypes.LOGIN,
          payload: res,
        });
        return { status: 'success' };
      })
      .catch(err => {
        return { status: 'error', errorMsg: err };
      });
  };
};

export const getAuthUser_TEST = () => {
  return dispatch => {
    return api.getAuthUser().then(res => {
      if (res.status === 'success') {
        if (res.status === 'success') {
          dispatch({
            type: ActionTypes.SET_AUTH_USER,
            payload: res.result.userInfo,
          });
        }
      }
      return res;
    });
  };
};

export const getAuthUser = () => {
  return dispatch => {
    return api
      .getAuthUser()
      .then(res => {
        dispatch({
          type: ActionTypes.SET_AUTH_USER,
          payload: res,
        });
        return { status: 'success' };
      })
      .catch(err => {
        return { status: 'error', errorMsg: err };
      });
  };
};

export const setSessionExpired = isExpired => {
  return {
    type: ActionTypes.SET_SESSION_EXPIRED,
    payload: isExpired,
  };
};

export const goLogin = from => {
  return dispatch => {
    dispatch({
      type: ActionTypes.LOGOUT,
    });
    history.push('/login');
    history.location.state = { from };
  };
};

export const setSettingsOpen = isOpen => {
  return {
    type: ActionTypes.SET_SETTINGS_OPEN,
    payload: isOpen,
  };
};

export const changeUserSettings = data => {
  return dispatch => {
    return api
      .changeUserSettings(data)
      .then(res => {
        dispatch({
          type: ActionTypes.SET_AUTH_USER,
          payload: res,
        });
        return { status: 'success' };
      })
      .catch(err => {
        return { status: 'error', errorMsg: err };
      });
  };
};

export const changeUserAvatar = file => {
  return dispatch => {
    return api
      .changeUserAvatar(file)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_USER_INFO,
          payload: { avatar_url: res.avatar_url },
        });
        return { status: 'success' };
      })
      .catch(err => {
        return { status: 'error', errorMsg: err };
      });
  };
};

export const updateUserInfo = payload => {
  return {
    type: ActionTypes.UPDATE_USER_INFO,
    payload,
  };
};

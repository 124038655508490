import React from 'react';
import { Grid } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
});

class MiscSettings extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        role="tabpanel"
        className={classes.root}
        hidden={this.props.tabValue !== this.props.tabIndex}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.content}
        >
          <DialogContentText variant="h6">Misc</DialogContentText>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(MiscSettings);

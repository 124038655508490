import { combineReducers, applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import App from './reducers/App';
import notifications from './reducers/notifications';
import searchHistory from './reducers/searchHistory';

const middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}
const composeEnhancers = composeWithDevTools({});
export const store = createStore(
  combineReducers({ App, notifications, searchHistory }),
  composeEnhancers(applyMiddleware(...middleware))
);

import ActionTypes from '../actions';

const notifications = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION:
      return [...state, action.payload];
    case ActionTypes.REMOVE_NOTIFICATION:
      return state.filter(notification => notification.key !== action.payload);
    case ActionTypes.LOGOUT:
    case ActionTypes.RESET_ALL:
      return [];
    default:
      return state;
  }
};

export default notifications;

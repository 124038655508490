import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { withStyles } from '@material-ui/core/styles';
import { getSettingsOpen, getUserInfo } from 'store/selectors/App';
import {
  setSettingsOpen,
  changeUserSettings,
  changeUserAvatar,
} from 'store/actions/App';
import { addNotification } from 'store/actions/notifications';

import ProfileSettings from './ProfileSettings';
import AppSettings from './AppSettings';
import MiscSettings from './MiscSettings';

const styles = theme => ({
  root: {
    minWidth: '620px',
  },
  header: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    overflow: 'hidden',
  },
  tabContainer: {
    height: '300px',
    overflowY: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  saveButton: {
    margin: theme.spacing(1),
  },
});

class SettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSomethingChanged: false, tab: 0 };
  }

  handleClose = () => {
    setTimeout(() => this.setState({ tab: 0 }), 1000);
    this.props.setSettingsOpen(false);
  };

  handleChangeTab = (e, newVal) => {
    this.setState({ tab: newVal });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.isSettingsOpen}
        //onClose={handleClose}
        fullWidth={true}
        //maxWidth="md"
        //className={classes.root}
        // https://stackoverflow.com/a/62324848
        classes={{ paper: classes.root }}
      >
        {/* <DialogTitle onClose={this.handleClose}>Settings</DialogTitle> */}
        <DialogTitle disableTypography className={classes.header}>
          <Typography variant="h6">Settings</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.content}>
          {/* <DialogContentText>
            You must be logged in to continue working
          </DialogContentText> */}
          <Tabs
            value={this.state.tab}
            onChange={this.handleChangeTab}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            //aria-label="icon label tabs example"
          >
            <Tab icon={<AccountCircleIcon />} label="Profile" />
            <Tab icon={<SettingsIcon />} label="App" />
            <Tab icon={<MoreHoriz />} label="Misc" />
          </Tabs>
          <div className={classes.tabContainer}>
            <ProfileSettings
              tabIndex={0}
              tabValue={this.state.tab}
              userInfo={this.props.userInfo}
              changeUserAvatar={this.props.changeUserAvatar}
              changeUserSettings={this.props.changeUserSettings}
              addNotification={this.props.addNotification}
            />
            <AppSettings tabIndex={1} tabValue={this.state.tab} />
            <MiscSettings tabIndex={2} tabValue={this.state.tab} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            variant="outlined"
            color="primary"
            //autoFocus
            className={classes.saveButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isSettingsOpen: getSettingsOpen,
  userInfo: getUserInfo,
});
//export default connect(mapStateToProps, { goLogin })(SettingsModal);
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setSettingsOpen,
    changeUserSettings,
    changeUserAvatar,
    addNotification,
  })
)(SettingsModal);

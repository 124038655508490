import React, { Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// https://dev.to/lakhansamani/how-to-use-react-suspense-and-lazy-for-code-splitting-3fo2
// https://medium.com/@stasonmars/%D0%BF%D0%BE%D0%BD%D0%B8%D0%BC%D0%B0%D0%B5%D0%BC-%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D1%8B-%D0%B2%D1%8B%D1%81%D1%88%D0%B5%D0%B3%D0%BE-%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-%D0%B2-react-%D0%BD%D0%B0-%D1%80%D0%B5%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D0%B5-12089f7e2ee6
const withSuspense = WrappedComponent => {
  return class ComposedComponent extends React.Component {
    render() {
      return (
        <Suspense
          fallback={
            <CircularProgress
              color="secondary"
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                //transform: 'translate(-50%, -50%)',
              }}
            />
          }
        >
          {<WrappedComponent {...this.props} />}
        </Suspense>
      );
    }
  };
};

export default withSuspense;

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

/*
history.listen(location => {
  console.log('>>>>>>>>>>>>> 000', location);
});
*/

export default history;

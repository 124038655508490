import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import * as Utils from 'utils';

const styles = theme => ({
  root: {
    // padding: theme.spacing(1),
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.common.white,
    // zIndex: theme.zIndex.drawer + 1,
  },
  uploadHint: {
    color: 'gray',
  },
  passwordInput: {
    width: 200,
  },
});

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: this.props.userInfo.login,
      email: this.props.userInfo.email ? this.props.userInfo.email : '',
      curPassword: '',
      newPassword: '',
      changeAvatarError: '',
      changeLoginError: '',
      changePasswordError: '',
      changeEmailError: '',
      showCurrentPassword: false,
      showNewPassword: false,
    };
  }

  handleChangeFile = e => {
    if (e.target) {
      if (e.target.files[0].size > 2097152) {
        this.setState({ changeAvatarError: 'File too big' });
        return;
      }

      this.props.changeUserAvatar(e.target.files[0]).then(res => {
        if (res.status === 'success') {
          this.setState({ changeAvatarError: '' });
          this.props.addNotification({
            message: 'Avatar was changed',
            type: 'success',
          });
        } else {
          this.setState({ changeAvatarError: res.errorMsg });
        }
      });
    }
  };

  handleChangeLogin = e => {
    this.setState({ login: e.target.value });
  };

  handleChangeCurPassword = e => {
    this.setState({ curPassword: e.target.value });
  };

  handleChangeNewPassword = e => {
    this.setState({ newPassword: e.target.value });
  };

  handleChangeEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleClickChangeLogin = () => {
    this.props.changeUserSettings({ new_login: this.state.login }).then(res => {
      if (res.status === 'success') {
        this.setState({ changeLoginError: '' });
        this.props.addNotification({
          message: 'Login was changed',
          type: 'success',
        });
      } else {
        this.setState({ changeLoginError: res.errorMsg });
      }
    });
  };

  handleClickChangePassword = () => {
    this.props
      .changeUserSettings({
        password: this.state.curPassword,
        new_password: this.state.newPassword,
      })
      .then(res => {
        if (res.status === 'success') {
          this.setState({
            changePasswordError: '',
            curPassword: '',
            newPassword: '',
          });
          this.props.addNotification({
            message: 'Password was changed',
            type: 'success',
          });
        } else {
          this.setState({ changePasswordError: res.errorMsg });
        }
      });
  };

  handleClickChangeEmail = () => {
    if (Utils.isValidEmail(this.state.email)) {
      this.props
        .changeUserSettings({ new_email: this.state.email })
        .then(res => {
          if (res.status === 'success') {
            this.setState({ changeEmailError: '' });
            this.props.addNotification({
              message: 'Email was changed',
              type: 'success',
            });
          } else {
            this.setState({ changeEmailError: res.errorMsg });
          }
        });
    } else {
      this.setState({ changeEmailError: 'Invalid email' });
    }
  };

  handleClickShowCurrentPassword = () => {
    this.setState(state => ({
      showCurrentPassword: !state.showCurrentPassword,
    }));
  };

  handleClickShowNewPassword = () => {
    this.setState(state => ({
      showNewPassword: !state.showNewPassword,
    }));
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        role="tabpanel"
        className={classes.root}
        hidden={this.props.tabValue !== this.props.tabIndex}
      >
        <Grid container direction="column">
          <Box>
            <Grid container alignItems="center">
              <Typography variant="subtitle1">Avatar</Typography>
              {this.state.changeAvatarError && (
                <Box ml={1}>
                  <Typography variant="caption" color="secondary">
                    {this.state.changeAvatarError}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Box mt={1}>
              <Grid container alignItems="center">
                <Avatar src={this.props.userInfo.avatar_url} />
                <Box ml={2}>
                  <input
                    type="file"
                    id="fileUploadButton"
                    hidden
                    //style={{ display: 'none' }}
                    accept=".jpeg, .png, .jpg"
                    onChange={this.handleChangeFile}
                  />
                  <label htmlFor="fileUploadButton">
                    <Button color="primary" size="small" component="span">
                      Upload photo or image
                    </Button>
                  </label>
                  <Typography variant="caption" className={classes.uploadHint}>
                    (No more then 2Mb size)
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Box>

          <Box mt={4}>
            <Grid container alignItems="center">
              <Typography variant="subtitle1">Login</Typography>
              {this.state.changeLoginError && (
                <Box ml={1}>
                  <Typography variant="caption" color="secondary">
                    {this.state.changeLoginError}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container alignItems="center">
              <TextField
                variant="outlined"
                margin="normal"
                size="small"
                required
                //fullWidth
                label="Login"
                //name="email"
                autoComplete="off"
                value={this.state.login}
                onChange={this.handleChangeLogin}
              />
              <Box ml={2} mt={1}>
                <Button
                  onClick={this.handleClickChangeLogin}
                  //variant="outlined"
                  variant="contained"
                  color="primary"
                  //autoFocus
                  //className={classes.saveButton}
                  size="small"
                  disabled={
                    this.state.login === '' ||
                    this.state.login === this.props.userInfo.login
                  }
                >
                  Change
                </Button>
              </Box>
            </Grid>
          </Box>

          <Box mt={4}>
            <Grid container alignItems="center">
              <Typography variant="subtitle1">Password</Typography>
              {this.state.changePasswordError && (
                <Box ml={1}>
                  <Typography variant="caption" color="secondary">
                    {this.state.changePasswordError}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Box display="flex" alignItems="center" mt={2}>
              {/* <TextField
                variant="outlined"
                margin="normal"
                size="small"
                required
                //fullWidth
                label="Current password"
                //name="email"
                autoComplete="off"
                value={this.state.curPassword}
                onChange={this.handleChangeCurPassword}
              /> */}
              <FormControl
                className={classes.passwordInput}
                size="small"
                variant="outlined"
              >
                <InputLabel htmlFor="password" required>
                  Current password
                </InputLabel>
                <OutlinedInput
                  type={this.state.showCurrentPassword ? 'text' : 'password'}
                  required
                  labelWidth={140}
                  autoComplete="off"
                  value={this.state.curPassword}
                  onChange={this.handleChangeCurPassword}
                  style={{ padding: 0 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleClickShowCurrentPassword}>
                        {this.state.showCurrentPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box ml={2}>
                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  size="small"
                  required
                  //fullWidth
                  label="New password"
                  //name="email"
                  autoComplete="off"
                  value={this.state.newPassword}
                  onChange={this.handleChangeNewPassword}
                /> */}
                <FormControl
                  className={classes.passwordInput}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="password" required>
                    New password
                  </InputLabel>
                  <OutlinedInput
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    required
                    labelWidth={120}
                    autoComplete="off"
                    value={this.state.newPassword}
                    onChange={this.handleChangeNewPassword}
                    style={{ padding: 0 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleClickShowNewPassword}>
                          {this.state.showNewPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box ml={2}>
                <Button
                  onClick={this.handleClickChangePassword}
                  //variant="outlined"
                  variant="contained"
                  color="primary"
                  //autoFocus
                  //className={classes.saveButton}
                  size="small"
                  disabled={
                    this.state.curPassword === '' ||
                    this.state.newPassword === '' ||
                    this.state.curPassword === this.state.newPassword
                  }
                >
                  Change
                </Button>
              </Box>
            </Box>
          </Box>

          <Box mt={4} mb={2}>
            <Grid container alignItems="center">
              <Typography variant="subtitle1">Email</Typography>
              {this.state.changeEmailError && (
                <Box ml={1}>
                  <Typography variant="caption" color="secondary">
                    {this.state.changeEmailError}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container alignItems="center">
              <TextField
                variant="outlined"
                margin="normal"
                size="small"
                required
                //fullWidth
                label="Email"
                //name="email"
                autoComplete="off"
                value={this.state.email}
                onChange={this.handleChangeEmail}
              />
              <Box ml={2} mt={1}>
                <Button
                  onClick={this.handleClickChangeEmail}
                  //variant="outlined"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    this.state.email === '' ||
                    this.state.email === this.props.userInfo.email
                  }
                >
                  Change
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ProfileSettings);

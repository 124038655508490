import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Router } from 'react-router-dom';
import history from 'components/Routing/history';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { SnackbarProvider } from 'notistack';
import Routing from 'components/Routing';
import Header from 'components/Header';
import MenuBar from 'components/MenuBar';
import ExpiredSessionModal from 'components/ExpiredSessionModal';
import Notifier from 'components/Notifier';
import SettingsModal from 'components/SettingsModal';
//import { loadHistory } from 'store/actions/searchHistory';

// https://mui.com/ru/components/data-grid/getting-started/#license-key-installation
import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(
  '0ad936d0afca038be1db176e0d93cfceT1JERVI6MTIzNDUsRVhQSVJZPTE2NzQxNjU2MDAwMDAsS0VZVkVSU0lPTj0x'
);

const styles = theme => ({
  '@global': {
    /*
    body: {
      background: 'red'
    },
    '#root': {
      background: 'red'
    }
    */
  },
  app: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    //height: '100vh',
    //background: 'red',
  },
});

class App extends React.Component {
  componentDidMount() {
    console.log('App ver:', process.env.REACT_APP_VERSION);
    //store.dispatch(loadHistory());
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Provider store={store}>
          <SnackbarProvider>
            <Router history={history}>
              <div className={classes.app}>
                <ExpiredSessionModal />
                <SettingsModal />
                <Notifier />
                <Header />
                <MenuBar />
                <div className={classes.content}>
                  <Toolbar />
                  <Routing />
                </div>
              </div>
            </Router>
          </SnackbarProvider>
        </Provider>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);

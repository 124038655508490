const ActionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_AUTH_USER: 'SET_AUTH_USER',
  RESET_ALL: 'RESET_ALL',
  SET_SESSION_EXPIRED: 'SET_SESSION_EXPIRED',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  SET_SETTINGS_OPEN: 'SET_SETTINGS_OPEN',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  SET_SEARCH_HISTORY: 'SET_SEARCH_HISTORY',
  UPDATE_SEARCH_HISTORY: 'UPDATE_SEARCH_HISTORY',
};

export default ActionTypes;

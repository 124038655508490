import ActionTypes from '../actions';

const searchHistory = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_HISTORY:
      return [...action.payload];
    case ActionTypes.UPDATE_SEARCH_HISTORY:
      return state;
    default:
      return state;
  }
};

export default searchHistory;

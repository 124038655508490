import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAuthenticated } from 'store/selectors/App';
import { getAuthUser } from 'store/actions/App';

// https://ui.dev/react-router-v4-protected-routes-authentication/
/*
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
*/
class PrivateRoute extends React.Component {
  state = {
    isChecked: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.getAuthUser().then(res => {
        if (res.status === 'success') {
          this.setState({ isChecked: true });
        }
      });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { isAuthenticated } = rest;

    if (!isAuthenticated && !this.state.isChecked) {
      return (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: getAuthenticated,
});
export default connect(mapStateToProps, { getAuthUser })(PrivateRoute);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import ImageLogo from 'assets/images/pibox_logo_wht.png';
import { withStyles } from '@material-ui/core/styles';
import { getAuthenticated, getUserInfo } from 'store/selectors/App';
import { logout, setSessionExpired, setSettingsOpen } from 'store/actions/App';
import { addNotification } from 'store/actions/notifications';
import * as api from 'api';

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: 'auto',
  },
  buttonAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  menuItemIcon: {
    minWidth: 40,
  },
  headerAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isMenuOpen: false };
    this.menuButtonRef = React.createRef();
  }

  handleToggleMenu = () => {
    this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
  };

  handleCloseMenu = e => {
    if (
      this.menuButtonRef.current &&
      this.menuButtonRef.current.contains(e.target)
    ) {
      return;
    }

    this.setState({ isMenuOpen: false });
  };

  handleClickLogout = () => {
    this.setState({ isMenuOpen: false });
    this.props.logout();
  };

  handleClickProfile = () => {
    this.setState({ isMenuOpen: false });
    //this.props.setSessionExpired(true);

    api
      .testRequest()
      .then(res => {
        console.log('>>>>>>>>> RESPONSE:', res);
      })
      .catch(err => {
        console.log('>>>>>>>>> ERROR:', err);
      });
  };

  handleClickSettings = () => {
    this.setState({ isMenuOpen: false });
    // success | error | warning | info
    //this.props.addNotification({ message: 'My notification', type: 'warning' });
    this.props.setSettingsOpen(true);
  };

  render() {
    if (!this.props.isAuthenticated) {
      return null;
    }

    const { classes } = this.props;
    return (
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Grid container direction="row" alignItems="center">
            <Avatar
              className={classes.headerAvatar}
              //alt="Lemy Sharp"
              variant="square"
              //src="/assets/images/pibox_boxlogo_wht.png"
              src={ImageLogo}
            />
            <Typography variant="subtitle1" color="inherit" noWrap>
              {`v.${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Grid>
          <IconButton
            ref={this.menuButtonRef}
            className={classes.menuButton}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={this.handleToggleMenu}
            color="inherit"
          >
            <Avatar
              src={this.props.userInfo.avatar_url}
              className={classes.buttonAvatar}
            />
          </IconButton>
          <Popper
            open={this.state.isMenuOpen}
            anchorEl={this.menuButtonRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  marginTop: 10,
                  marginRight: 5,
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleCloseMenu}>
                    <MenuList
                      autoFocusItem={this.state.isMenuOpen}
                      //id="menu-list-grow"
                    >
                      {/* <MenuItem onClick={this.handleClickProfile}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                      </MenuItem> */}
                      <MenuItem onClick={this.handleClickSettings}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={this.handleClickLogout}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
    );
  }
}

//export default withStyles(styles)(Header);
const mapStateToProps = createStructuredSelector({
  isAuthenticated: getAuthenticated,
  userInfo: getUserInfo,
});
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    logout,
    setSessionExpired,
    addNotification,
    setSettingsOpen,
  })
)(Header);

import ActionTypes from '../actions';

let lastKey = 0;

export const addNotification = notification => {
  return {
    type: ActionTypes.ADD_NOTIFICATION,
    payload: { ...notification, key: ++lastKey },
  };
};

export const removeNotification = key => {
  return {
    type: ActionTypes.REMOVE_NOTIFICATION,
    payload: key,
  };
};

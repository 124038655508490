import ActionTypes from '../actions';

const defaultState = {
  userInfo: null,
  isAuthenticated: false,
  isSessionExpired: false,
  isSettingsOpen: false,
};

const App = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
    case ActionTypes.SET_AUTH_USER:
      return { ...state, isAuthenticated: true, userInfo: action.payload };
    case ActionTypes.UPDATE_USER_INFO:
      return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
    case ActionTypes.LOGOUT:
    case ActionTypes.RESET_ALL:
      return defaultState;
    case ActionTypes.SET_SESSION_EXPIRED:
      return { ...state, isSessionExpired: action.payload };
    case ActionTypes.SET_SETTINGS_OPEN:
      return { ...state, isSettingsOpen: action.payload };
    default:
      return state;
  }
};

export default App;

export function isValidEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

// https://stackoverflow.com/a/60964904
export function scrollToElement(selector, yOffset = 0) {
  const el = document.querySelector(selector);
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
}

export function initFeatures(data) {
  let result = [];
  for (const feature of data) {
    const monthlyPrice = feature.price_info
      ? Number((feature.price_info.monthly_unit_amount / 100).toFixed(2))
      : 0;
    const annualPrice = feature.price_info
      ? Number((feature.price_info.annual_unit_amount / 100).toFixed(2))
      : 0;

    result.push({
      id: feature.id,
      monthly_price: monthlyPrice,
      annual_price: annualPrice,
      title: feature.title,
      count: feature.price_info
        ? feature.price_info.count === null
          ? 0
          : feature.price_info.count
        : 0,
      countLimit: feature.price_info
        ? feature.price_info.count_limit === null
          ? 0
          : feature.price_info.count_limit
        : 0,
      extendingType: feature.price_info
        ? feature.price_info.extending_type === null
          ? 'fixed'
          : feature.price_info.extending_type
        : 'fixed',
      description: feature.description,
      priceInfo: feature.price_info
        ? {
            product: {
              id: feature.price_info.product_id,
              name: feature.price_info.product_name,
              type: feature.price_info.product_type,
            },
            price: {
              id: feature.price_info.id,
              name: feature.price_info.name,
              monthlyPrice,
              annualPrice,
              annualMonthlyPrice: Number(
                (feature.price_info.annual_unit_amount / 12).toFixed(2)
              ),
              valueType:
                feature.price_info.value_type === 'integer'
                  ? 'number'
                  : 'string',
              valueUnits: feature.price_info.unit,
              valueMeaning: feature.price_info.value,
            },
            shortInfo: `${feature.price_info.name} (${monthlyPrice}$ / ${annualPrice}$), ${feature.price_info.product_name}`,
            tooltipInfo: `Price ID: ${feature.price_info.id}\nPrice name: ${feature.price_info.name}\nPrice value: ${feature.price_info.value} / ${feature.price_info.unit} / ${feature.price_info.value_type}\nProduct ID: ${feature.price_info.product_id}\nProduct name: ${feature.price_info.product_name}\nProduct type: ${feature.price_info.product_type}`,
          }
        : null,
    });
  }
  return result;
}

export function readablizeBytes(bytes = 0) {
  if (bytes === 0) {
    return '0.00 kB';
  }
  let s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  let e = Math.floor(Math.log(bytes) / Math.log(1000));
  return (bytes / Math.pow(1000, e)).toFixed(2) + ' ' + s[e];
}
